var site = site || {};
site.endeca = site.endeca || {};
site.endeca.instances = site.endeca.instances || {};
site.endeca.instances.foundation_finder_dashboard = site.endeca.instances.foundation_finder_dashboard || {};


function set_foundation_finder_dashboard_configuration ( $html ) {
    return jQuery.extend( true, {}, site.endeca.configuration, {
        followRedirects: false,
        
        nodes: {
            resultsContainer: $html.find('.foundation-finder-results')
        },
        
        queries: {
            product: {
                recordsPerPage: 1,
                recordFilters: ['activeSkus', 'products']
            }
        },
        
        results: {
            products: {
                baseClass: 'site.endeca.results',
                instanceArgs: {
                    parentNode: $html.find('.foundation-finder-results'),
                    childTemplatePath: "/templates/foundation_finder/dashboard/products/result.tmpl"
                }
            }
        }
    });

}
